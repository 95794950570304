<template>
  <div>
    <FormFilter @set-action-filter="action = $event">
      <template slot="default">        
        <CInput id="general_parameter_seal_id" label="ID" v-model="filter.general_parameter_seal_id" />
        <CSelect id="seal_id" label="Certificadora" :options="sealOptions" v-model="filter.seal_id" @update:value="filter.seal_id = $event"/>
        <CInput id="client_alias" label="Alias" v-model="filter.client_alias" />
      </template>
    </FormFilter>
  </div>
</template>

<script>
import FormFilter from "@/components/FormFilter";

export default {
  name: "GeneralParameterSealFilter",
  components: { FormFilter },
  props: {
    sealOptions: Array
  },
  data() {
    return {
      action: {},
      filter: {}
    }
  },
  methods: {
    apply() { 
      this.filter.apply = true
      this.triggerEvent()     
    },
    reset() {
      this.filter = {}
      this.filter.reset = true
      this.triggerEvent()   
    },
    triggerEvent() { 
      this.$emit('filter-general-parameter-seal', this.filter);
      this.action = {}   
      if(this.filter.reset) this.filter = {}
    }
  },
  watch: {
    action() {
      if(this.action === 'applyFilter') {
        this.apply()     
      } else if(this.action === 'resetFilter') {
        this.reset()        
      }
    }
  }
}
</script>
